import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Storage } from '@capacitor/storage';
import { AuthService } from '../auth/auth.service';
//import { Storage } from '@ionic/storage-angular';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
   constructor(private authServices: AuthService) {

   }
   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     // eslint-disable-next-line no-debugger
   //debugger;
   // JSON.parse((await Storage.get({ key: 'user' })).value)
    const token =  this.authServices.userToken;

    if (token) {
        req = req.clone({
            setHeaders: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: `Bearer ${token}`
            }
        });
    }
    return next.handle(req);
  }
  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   // convert promise to observable using 'from' operator
  //   return from(this.handle(req, next));
  // }

  // async handle(req: HttpRequest<any>, next: HttpHandler) {
  //   // if your getAuthToken() function declared as "async getAuthToken() {}"
  //   const token =  await Storage.get({ key: 'token' });

  //   // if your getAuthToken() function declared to return an observable then you can use
  //   // await this.auth.getAuthToken().toPromise()

  //   const authReq = req.clone({
  //     setHeaders: {
  //       // eslint-disable-next-line @typescript-eslint/naming-convention
  //       Authorization: `Bearer ${token}`
  //     }
  //   });
  //     // eslint-disable-next-line no-debugger
  //    debugger;
  //   // Important: Note the .toPromise()
  //   return next.handle(authReq).toPromise();
  // }

    // getToken = async ()  => {
    //   const  value  =  await Storage.get({ key: 'token' });

    //   return value;
    // };
}
