export interface IUser {
  id: string;
  email: string;
  displayName: string;
  avatar: string;
  token: string;
  occupation: string;
  nickName: string;
  userName: string;
  roleNames: string;
  userLogo: string;
  roles: string[];
  photos: UserPhoto[];
}
export interface UserPhoto {
  id: number;
  url: string;
  isMain: boolean;
}
export class User {

  id: string;
  email: string;
  displayName: string;
  avatar: string;
  userToken: string;
  occupation: string;
  nickName: string;
  userName: string;
  roleNames: string;
  roles: string[];
  photos: UserPhoto[];
  tokenExpirationDate: Date;

  get token() {
    if (!this.tokenExpirationDate || this.tokenExpirationDate <= new Date()) {
      return null;
    }
    // eslint-disable-next-line no-underscore-dangle
    return this.userToken;
  }

  get tokenDuration() {
    if (!this.token) {
      return 0;
    }
    return this.tokenExpirationDate.getTime() - new Date().getTime();
  }
}
// export class UserLogIn {
//   email= 'harley_douglas@yahoo.com';
//   password = 'Pa$$w0rd';
// }
export class UserLogIn {
  email= '';
  password = '';
}

