import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  // constructor(private authservice: AuthService, private router: Router) {}
  // canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
  //     if(!this.authservice.userIsAuthenticated) {
  //       this.router.navigateByUrl('/auth');
  //     }
  //     return this.authservice.userIsAuthenticated;
  // }
  constructor(private authService: AuthService, private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.currentUser$.pipe(
      take(1),
      map(user => {
        // eslint-disable-next-line no-debugger
        // debugger;
        if (user) { return true; }
        this.router.navigateByUrl('/auth');
        })
      );
  }

}
