import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Capacitor} from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { IUser, User } from './Models/User';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  currentUser$: Observable<User>;
  userDetails: User;
  constructor(
    private menu: MenuController,
    public authServices: AuthService,
    private platform: Platform,
    private router: Router) {
      this.initializeApp();
    }

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openEnd() {
    this.menu.open();
  }

  onLogout() {
    // eslint-disable-next-line no-debugger
   // debugger;
    this.authServices.logout();
    this.router.navigateByUrl('/auth');
   }
   ngOnInit(): void {
    this.authServices.loadCurrentUser();
    this.currentUser$ = this.authServices.currentUser$;
    this.authServices.currentUser$.subscribe(user => {
      this.userDetails = user;
    });
  }
  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        SplashScreen.hide();
      }
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
    });
  }
}

