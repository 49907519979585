import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Storage } from '@capacitor/storage';
import { IUser, User } from '../Models/User';
import { AuthResponseData } from '../Models/AuthResponseData';
import { environment } from 'src/environments/environment';
import { Http } from '@capacitor-community/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy{
  private user = new BehaviorSubject<User>(null);
  private currentUserSource: ReplaySubject<User> = new ReplaySubject<User>(null);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  currentUser$ = this.currentUserSource.asObservable();

  private activeLogoutTimer: any;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  baseUrl = environment.apiUrl;
  private isUserIsAuthenticated = false;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  userToken = '';

 get userIsAuthenticated() {
  return this.user.asObservable().pipe(
    map(user => {
      if (user) {
        return !!user.token;
      } else {
        return false;
      }
    })
  );
 }

get userId() {
  return this.user.asObservable().pipe(
    map(user => {
      if (user) {
        return user.id;
      } else {
        return null;
      }
    })
  );
}

get token() {
  return this.user.asObservable().pipe(
    map(user => {
      if (user) {
        return user.token;
      } else {
        return null;
      }
    })
  );
}
constructor(private http: HttpClient) {}

autoLogin() {
  return from(Storage.get({ key: 'authData' })).pipe(
    map(storedData => {
      if (!storedData || !storedData.value) {
        return null;
      }
      const parsedData = JSON.parse(storedData.value) as {
        token: string;
        tokenExpirationDate: string;
        userId: string;
        email: string;
      };
      const expirationTime = new Date(parsedData.tokenExpirationDate);
      if (expirationTime <= new Date()) {
        return null;
      }
      const user = new User();
       user.id = parsedData.userId;
       user.email = parsedData.email;
       user.userToken = parsedData.token;
       user.tokenExpirationDate = expirationTime;

      return user;
    }),
    tap(userResult => {
      if (userResult) {
        // eslint-disable-next-line no-underscore-dangle
        this.user.next(userResult);
        this.autoLogout(userResult.tokenDuration);
      }
    }),
    map(userResult => !!userResult)
  );
}

  signup(email: string, password: string) {
    //return this.http.get<IJobinvite[]>(this.baseUrl + `Jobinvites/GetJobinvitesByUser`);
    return this.http
      .post<AuthResponseData>(this.baseUrl + 'Account/login',
        { email, password, returnSecureToken: true }
      )
      .pipe(tap(this.setUserData.bind(this)));
  }

  // login(email: string, password: string) {
  //   return this.http
  //     .post<AuthResponseData>(this.baseUrl + 'Account/login',
  //       { email, password, returnSecureToken: true }
  //     )
  //     .pipe(tap(this.setUserData.bind(this)));
  // }

 async logout() {
    // if (this.activeLogoutTimer) {
    //   clearTimeout(this.activeLogoutTimer);
    // }
    // eslint-disable-next-line no-debugger
   // debugger;
    this.user.next(null);
    await  Storage.remove({ key: 'user' });
    await  Storage.remove({ key: 'token' });
  }
  login(values: any) {

    return this.http.post(this.baseUrl + 'Account/login', values).pipe(
      map((user: User) => {
        if (user) {
          this.setCurrentUser(user);
          this.currentUserSource.next(user);
          return user;
        }
      })
    );
  }
  loginUser(value: any): Observable<User> {
      // eslint-disable-next-line no-debugger
      //debugger;
       return from(Http.request({
        method: 'POST',
        url: this.baseUrl + 'Account/login',
        headers: {'content-type' : 'application/json'},
        data: value
      }))
      .pipe(map(result => {
        const user = result.data as User;
        this.setCurrentUser(user);
        this.currentUserSource.next(user);
        return user;
      }));
  }
  async setCurrentUser(user: User){
    user.roles = [];
    this.userToken =  user.token;
    await  Storage.set({ key: 'user', value: JSON.stringify(user) });
    await  Storage.set({ key: 'token', value: user.token });
  }
  async loadCurrentUser() {
    // eslint-disable-next-line no-debugger
    // debugger;
    const storeUser = JSON.parse((await Storage.get({ key: 'user' })).value);
    if(storeUser) {
      const user = new User();
      user.id =  storeUser.id;
      user.displayName= storeUser.displayName;
      user.avatar = storeUser.avatar;
      user.userToken = storeUser.token;
      user.occupation= storeUser.occupation;
      user.nickName= storeUser.nickName;
      user.userName= storeUser.userName;
      user.tokenExpirationDate = storeUser.tokenExpirationDate;;
      this.userToken =  storeUser.token;
      this.currentUserSource.next(user);
    }


    // if (user.token === null) {
    //   this.currentUserSource.next(null);
    //   return of(null);
    // }
    // //const user = await Storage.get({ key: 'user' });
    //  if (user) {
    //    debugger;
    //    // this.currentUserSource.next(user);
    //  }
  }
  ngOnDestroy() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
  }

  private autoLogout(duration: number) {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    this.activeLogoutTimer = setTimeout(() => {
      this.logout();
    }, duration);
  }

  private setUserData(userData: AuthResponseData) {
    // eslint-disable-next-line no-debugger
    // debugger;
    const expirationTime = new Date(userData.expiresIn);

    const user = new User();
    user.id = userData.id;
    user.email = userData.email;
    user.userToken = userData.token;
    user.tokenExpirationDate = expirationTime;

    this.user.next(user);
    //this.autoLogout(user.tokenDuration);
    this.storeAuthData(
      userData.id,
      userData.token,
      expirationTime.toISOString(),
      userData.email
    );
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  updateCurrentUser(user: User): void {
    this.user.next(user);
  }
  private async storeAuthData(
      userId: string, token: string, tokenExpirationDate: string,  email: string ) {
      const data = JSON.stringify({
        userId,
        token,
        tokenExpirationDate,
        email
      });
      await  Storage.set({ key: 'authData', value: data });
      await  Storage.set({ key: 'token', value: token });
    }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  checkEmailExists(email: string) {
    return this.http.get(this.baseUrl + 'account/emailexists?email=' + email);
  }

}
